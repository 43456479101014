import {Notification, toast} from 'components/ui';
import BaseService from './BaseService';

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then(response => {
          resolve(response);
          if (response?.data?.success === false) {
            toast.push(
              <Notification
                title={
                  response?.data?.title ? response?.data?.title : 'NOTIFICACIÓN'
                }
                type="danger"
                duration={6000}
                closable
              >
                <div class="w-64">
                  <p class="break-words whitespace-normal">
                    {response?.data?.message
                      ? response?.data?.message
                      : 'HA OCURRIDO UN ERROR'}
                  </p>
                </div>
              </Notification>,
              {
                placement: 'top-center'
              }
            );
          }
        })
        .catch(e => {
          console.log('Error', e);
          toast.push(
            <Notification
              title={
                e.response?.data?.title
                  ? e.response?.data?.title
                  : 'NOTIFICACIÓN'
              }
              type="danger"
              duration={6000}
              closable
            >
              <div class="w-64">
                <p class="break-words whitespace-normal">
                  {e.response?.data?.message
                    ? e.response?.data?.message
                    : 'HA OCURRIDO UN ERROR'}
                </p>
              </div>
            </Notification>,
            {
              placement: 'top-center'
            }
          );
          reject(e);
        });
    });
  }
};

export default ApiService;
